// frameCardName: Planewide Celebration
// frameCardSet: WAR

import React from 'react'

import Layout from 'components/layout/Layout'
import PageMetaData from 'components/layout/PageMetaData'
import CubeWillOutliveMagicPlaymatConfirmation from 'src/components/shop/CubeWillOutliveMagicPlaymatConfirmation'

interface Props {
  pageContext: any
}

const PlaymatConfirmation: React.FC<Props> = (props) => {
  return (
    <Layout
      {...props.pageContext}
      accentColor="#B63622"
      includeNewsletterSignup
    >
      <CubeWillOutliveMagicPlaymatConfirmation />
    </Layout>
  )
}

export const Head: React.FC<Props> = (props) => {
  return (
    <PageMetaData
      url={props.pageContext.url}
      metaData={{
        noindex: true,
        title: 'Thank you for your order! - Lucky Paper',
        description: 'Thank you for your order!',
        ogImageURL: 'https://luckypaper.co/og-images/cube-con-2023-print.jpg',
      }}
    />
  )
}

export default PlaymatConfirmation
